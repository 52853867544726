<template>
  <div>
    <div v-show="showHotspot" id="ref4-hotspot">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'Ref4_Hotspot',
  data () {
    return {
      showHotspot: false
    }
  },
  props: {
    label: { required: true, type: String }
  },
  mounted () {
    window.addEventListener(
      'Ref4_ChangeOverviewState',
      (e) => {
        this.showHotspot = e.detail.show
      },
      false
    )
  }
}
</script>

<style lang="scss" scoped>
#ref4-hotspot {
  background-color: black;
  border: 1px solid #ff6600;
  border-radius: 8px;
  -moz-border-radius: 8px;

  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
</style>
