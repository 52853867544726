<template>
  <div id="ref4-colormenu-flex">
    <div id="colormenuwrapper" class="menu-size absolute hiddentolower">
      <div id="colormenu-upper" class="flex-row align-items-left">
        <div class="colormenu-upper-hl">
          <h3>{{ colorMenuHeadline }}</h3>
        </div>
        <div id="separatorv" v-show="currentColorId >= 0"></div>
        <div class="colormenu-upper-sl">
          <h3 v-show="currentColorId >= 0">[{{ currentColorName }}]</h3>
        </div>
      </div>
      <div id="colormenu-lower" class="flex-row">
        <color-button
          v-for="(item, index) in colorSetup"
          :key="item.name"
          :color="item.color"
          :id="'color' + index"
          :mainApplication="mainApplication"
          :useAsToggle="true"
          :useRadioLogic="true"
          :show="availableColors[index]"
          >></color-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ColorButton from './ColorButton.vue'
export default {
  name: 'Ref4ColorMenu',
  components: {
    ColorButton
  },
  data () {
    return {
      colorMenuHeadline: '',
      currentColorId: -1,
      colorSetup: [],
      partSetup: [],
      mainApplication: 'Ref4_Color',
      availableColors: [],
      show: false
    }
  },
  props: {
    // show: { required: true, type: Boolean },
  },
  computed: {
    currentColorName () {
      return this.colorSetup && this.colorSetup[this.currentColorId]
        ? this.colorSetup[this.currentColorId].name
        : ''
    }
  },
  watch: {
    show () {
      // console.log("watch ", this.id, this.show)
      if (this.show) {
        document
          .getElementById('colormenuwrapper')
          .classList.remove('hiddentolower')
      } else {
        document
          .getElementById('colormenuwrapper')
          .classList.add('hiddentolower')
      }
    }
  },
  mounted () {
    this.changeData(require('@/data/content_reference4.json'))
    window.addEventListener(
      this.mainApplication + '_Click',
      (e) => {
        // console.log(e.detail.id)
        var str = e.detail.id
        var res = str.replace('color', '')
        this.currentColorId = res
      },
      false
    )
    window.addEventListener(
      this.mainApplication + '_SetColorId',
      (e) => {
        // console.log(e.detail.id)
        this.currentColorId = e.detail.id
      },
      false
    )
    window.addEventListener(
      'Ref4_Config_Click',
      (e) => {
        const id = e.detail.id

        if (id.length === 3 && id.startsWith('0.')) {
          var subid = id.replace('0.', '')
          this.setAvailableColors(subid)
        }
      },
      false
    )
    window.addEventListener(
      'Ref4_ChangeColorMenuState',
      (e) => {
        this.show = e.detail.show
      },
      false
    )
  },
  methods: {
    changeData: function (data) {
      this.colorMenuHeadline = data.menu.menuSetup.colorsHeadline
      this.colorSetup = data.menu.colorSetup
      this.partSetup = data.menu.partSetup

      this.colorSetup.forEach((color) => {
        this.availableColors.push(false)
      })
    },
    setAvailableColors: function (partid) {
      for (let i = 0; i < this.colorSetup.length; i++) {
        var color = this.colorSetup[i]

        this.availableColors[i] = this.partSetup[partid].colors.includes(
          color.name
        )
        // console.log(i, color.name, this.partSetup[partid].colors)
      }
      // console.log(this.availableColors)

      const event = new CustomEvent(
        this.mainApplication + '_SetAvailableColors',
        {
          detail: this.availableColors
        }
      )
      window.dispatchEvent(event)
    }
  }
}
</script>

<style lang="scss" scoped>
.colormenu-upper-hl h3 {
  color: white !important;
  text-align: left;
}

.colormenu-upper-sl h3 {
  color: #3e3e3e;
  text-align: left;
}
#separatorv {
  width: 1.5px;
  height: 38px;
  background-color: #3e3e3e;
  margin: 10px;
  margin-top: 5px;
}
@font-face {
  font-family: "HelveticaLTStd-UltraComp";
  src: url("/fonts/Helvetica_neue/HelveticaLTStd-UltraComp.ttf");
}
#colormenu-upper {
  font-family: "HelveticaLTStd-UltraComp";
}
.menu-size {
  height: 120px;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -o-transition: height 0.5s;
  width: 400px;
}
.absolute {
  position: absolute;
  margin: auto;
  bottom: 40px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.align-items-right {
  align-items: flex-end;
}
.align-items-left {
  justify-content: flex-start;
  align-items: flex-start;
}
#ref4-colormenu-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#colormenuwrapper {
  background-color: black;
  border: 1px solid #ff6600;
  border-radius: 8px;
  -moz-border-radius: 8px;
  //   display: unset;

  //   transition-delay: display 0s;
  //   transform: translateY(0px);
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;

  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
.hiddentolower {
  // display: none;
  // opacity: 0;
  // visibility: hidden;
  //   transition-delay: display 1s;
  transform: translateY(200px) !important;
  -moz-transform: translateY(200px) !important;
  -webkit-transform: translateY(200px) !important;
  -o-transform: translateY(200px) !important;
  -ms-transform: translateY(200px) !important;

  transition: all 1s !important;
  -webkit-transition: all 1s !important;
  -moz-transition: all 1s !important;
  -o-transition: all 1s !important;
}
@media (max-device-height: 600px) {
  .absolute {
    bottom: 20px;
  }
}
@media (max-device-height: 400px) {
  .absolute {
    bottom: 20px;
    transform: scale(0.6);
    transform-origin: bottom;
  }
}
@media (max-device-width: 500px) {
  .absolute {
    bottom: 20px;
    transform: scale(0.8);
    transform-origin: bottom;
  }
}
@media (max-device-width: 400px) {
  .absolute {
    bottom: 20px;
    transform: scale(0.6);
    transform-origin: bottom;
  }
}
</style>
