<template>
  <div>
    <div id="container-reference-4">
      <canvas id="canvas-reference-4"></canvas>
    </div>
    <div id="container-reference-4-overlay">
      <!-- <pulse-loader
        id="pulseLoader"
        :loading="loading"
        :color="color"
      ></pulse-loader> -->
      <div id="ref4-menu-flex" class="left-top">
        <div class="flex-column">
          <b-button
            variant="primary"
            id="back"
            class="rounded-circle px-2"
            @click="routerGoBack()"
          >
            <b-icon icon="chevron-left" scale="1"></b-icon>
          </b-button>
          <b-button
            variant="primary"
            id="fullscreen"
            class="rounded-circle px-2"
            @click="toggleFullscreen()"
          >
            <b-icon
              v-if="!fullscreenState"
              icon="fullscreen"
              scale="1"
            ></b-icon>
            <b-icon
              v-if="fullscreenState"
              icon="fullscreen-exit"
              scale="1"
            ></b-icon>
          </b-button>
          <img src="img/360_icon.webp" class="icon360" alt="" />
        </div>
        <ref-4-main-menu-animated class="menu"></ref-4-main-menu-animated>
      </div>

      <ref-4-setup-overview
        :show="showOverview"
        :price="price"
      ></ref-4-setup-overview>

      <ref-4-color-menu></ref-4-color-menu>

      <!-- Hotspots -->
      <ref-4-hotspots
        v-for="item in hotspots"
        :key="item.name"
        class="hotspot scale"
        :id="item.name"
        :label="item.label"
      ></ref-4-hotspots>
      <!-- Desktop Hint
      <div id="hint">
        <div id="hint-text">
          <p>
            {{ mobileHint }}
          </p>
        </div>
        <div id="hint-progress"></div>
      </div> -->

      <!-- Backdrop -->
      <div id="backdrop"></div>

      <!-- Loading Screen -->
      <ref-loading-screen
        class="loadingscreen-container"
        :image="loadingScreen.image"
        :textarray="loadingScreen.textarray"
        :progresslabel="loadingScreen.progresslabel"
        :progressvalue="loadingScreen.progressvalue"
        :progressmax="loadingScreen.progressmax"
        :headline="loadingScreen.headline"
        :subline="loadingScreen.subline"
        :mobileHint="loadingScreen.mobileHint"
        :ismobile="isMobile"
        :referenceid="loadingScreen.referenceid"
        :loadingcompleted="loadingScreen.loadingcompleted"
      ></ref-loading-screen>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Ref4MainMenuAnimated from '@/components/Ref4_MainMenu_Animated.vue'
import Ref4SetupOverview from '@/components/Ref4_SetupOverview.vue'
import Ref4ColorMenu from '@/components/Ref4_ColorMenu.vue'
import Ref4Hotspots from '@/components/Ref4_Hotspots.vue'
import store from '../store'
import RefLoadingScreen from '../components/Ref_LoadingScreen.vue'

export default {
  name: 'Reference4',
  data () {
    return {
      fullscreenState: false,
      color: '#00ffd6',
      loading: true,
      mobileHint: '',
      script: '',
      activated: false,
      showOverview: false,
      // showColorMenu: false,
      isMobile: false,
      hotspots: [],
      loadingScreen: {
        image: '',
        progresslabel: '',
        textarray: [],
        progressvalue: 0,
        progressmax: 1,
        headline: '',
        subline: '',
        mobileHint: '',
        referenceid: '',
        loadingcompleted: false
      }
    }
  },
  computed: {
    price () {
      return this.$store.state.price
    }
  },
  mounted () {
    // console.log("ref1 mounted");

    window.addEventListener(
      'ref4_loadingprogress',
      (e) => {
        this.loadingScreen.progressvalue = e.detail

        if (e.detail === 1) {
          var that = this
          setTimeout(function () {
            that.loadingScreen.loadingcompleted = true
          }, 1000)
        }
      },
      false
    )
    window.addEventListener(
      'Ref4_ModelLoaded',
      (e) => {
        // document.getElementById("pulseLoader").classList.add("hidden");

        this.loading = false
      },
      false
    )
    window.addEventListener(
      'Ref4_ChangeOverviewState',
      (e) => {
        this.showOverview = e.detail.show
      },
      false
    )
    window.addEventListener(
      'Ref4_HotspotPositionChanged',
      (e) => {
        // console.log("HOTSPOT " + e.detail.id + " CHANGED - x: " + e.detail.x + ", y: " + e.detail.y );
        if (this.activated) {
          document.getElementById('Hotspot' + e.detail.id).style.top =
            e.detail.y + 'px'
          document.getElementById('Hotspot' + e.detail.id).style.left =
            e.detail.x + 'px'
        }
      },
      false
    )
    window.addEventListener(
      'ref4_LoadingScreenClosed',
      (e) => {
        if (this.activated) {
          document.getElementById('backdrop').classList.add('hidden')
        }
      },
      false
    )

    const event = new CustomEvent('Ref4_ChangeOverviewState', {
      detail: {
        show: false
      }
    })
    window.dispatchEvent(event)

    const event2 = new CustomEvent('Ref4_ChangeColorMenuState', {
      detail: {
        show: false
      }
    })
    window.dispatchEvent(event2)

    this.changeData(require('@/data/content_reference4.json'))

    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4)) ||
    navigator.userAgent.toLowerCase().match(/mobile/i)) {
      // console.log("MOBIL")
      this.isMobile = true
    } else {
      // console.log("DESKTOP")
      this.isMobile = false
    }

    this.script = require('@/scripts/reference4_3dcontent.js')
    this.script2 = require('@/scripts/reference4_programflow.js')

    this.script.main(this.isMobile)
  },
  activated () {
    this.activated = true
    const that = this

    const event = new Event('Ref4_routeActivated')
    window.dispatchEvent(event)

    this.keepalive = this.script

    // setTimeout(function () {
    //   if (that.activated) {
    //     document.getElementById("hint").classList.add("fadeout");
    //   }
    // }, 7000);
    // setTimeout(function () {
    //   if (that.activated) {
    //     document.getElementById("hint-progress").classList.add("scaledown");
    //   }
    // }, 2000);
  },
  deactivated () {
    this.activated = false

    const event = new Event('Ref4_routeDeactivated')
    window.dispatchEvent(event)

    this.keepalive = this.script
  },
  components: {
    // PulseLoader,
    Ref4MainMenuAnimated,
    Ref4SetupOverview,
    Ref4ColorMenu,
    Ref4Hotspots,
    RefLoadingScreen
  },
  methods: {
    toggleFullscreen () {
      this.fullscreenState = !this.fullscreenState

      if (this.fullscreenState) {
        document.documentElement.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    },
    routerGoBack () {
      if (this.fullscreenState) {
        this.toggleFullscreen()
      }
      // this.$router.go(-1);
      this.$router.push({ path: '/#' + store.state.activeHomeViewHash })
    },
    changeData: function (data) {
      this.loadingScreen.image = data.loadingScreen.image
      this.loadingScreen.progresslabel = data.loadingScreen.progresslabel
      this.loadingScreen.headline = data.loadingScreen.headline
      this.loadingScreen.subline = data.loadingScreen.subline
      this.loadingScreen.mobileHint = data.loadingScreen.mobileHint
      this.loadingScreen.referenceid = data.loadingScreen.referenceid
      this.loadingScreen.textarray = data.loadingScreen.textarray

      for (let i = 0; i < data.menu.menuSetup.hotspots.length; i++) {
        const item = {}
        item.label = data.menu.menuSetup.hotspots[i]
        item.id = i
        item.name = i < 10 ? 'Hotspot0' + (i + 1) : 'Hotspot' + (i + 1)
        this.hotspots.push(item)
        // console.log(item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loadingscreen-container {
  position: absolute;

  top: 50%;

  left: 50%;

  -moz-transform: translateX(-50%) translateY(-50%);

  -webkit-transform: translateX(-50%) translateY(-50%);

  transform: translateX(-50%) translateY(-50%);
}
#backdrop {
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #000000bb;
  z-index: 1000;
  position: absolute;
  pointer-events: all;
}
.hotspot {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: auto;
}
#ref4-menu-flex {
  display: flex;
  flex-direction: column;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu {
  margin-top: 50px;
}
.icon360 {
  // position: absolute;
  // top: 150px;
  // left: 34px;
  width: 50px;
  height: auto;
  position: relative;
  // left: -7px;
  margin-top: 5px;
  margin-bottom: 5px;
}
#hint {
  bottom: 300px;
  margin-left: 40px;
  margin-right: 40px;
  max-width: 350px;
  line-height: 1;
  position: absolute;
  background-color: rgba(0, 6, 12, 0.6);
  opacity: 1;
  display: none;
}
#hint-text {
  padding: 20px;
  padding-bottom: 2px;
}
#hint-progress {
  background-color: #00ffd6;
  height: 5px;
  width: 100%;
}
#hint-progress.scaledown {
  width: 0.1%;
  transition: 5s;
  -webkit-transition: 5s;
  -moz-transition: 5s;
  -o-transition: 5s;
}
#hint.fadeout {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  opacity: 0;
}
#container-reference-4 {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
}
#container-reference-4-overlay {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  pointer-events: none;
  overflow: hidden;
}
#canvas-reference-4 {
  top: 76px;
  left: 0px;
  position: absolute;
}
#show-hotspots {
  margin-top: 10px;
  margin-bottom: 10px;
}
#show-hotspots,
#back {
  pointer-events: auto;
  width: 38px;
}
#fullscreen {
  pointer-events: auto;
  margin-top: 10px;
}

.pointer-all {
  pointer-events: all;
}
#pulseLoader {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 25;
}
.hidden {
  display: none;
}
.left-bottom {
  position: absolute;
  bottom: 40px;
  left: 40px;
}
.left-top {
  position: absolute;
  top: 100px;
  left: 40px;
  z-index: 25;
}
.white-text {
  color: white;
  font-size: 1rem;
}
.btn-primary {
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:hover {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00977e77;
}
@media (pointer: coarse) {
  #hint {
    display: block;
  }
}
@media (max-device-height: 600px) {
  #ref4-menu-flex {
    flex-direction: row;

    transform: scale(0.8);
    transform-origin: top;
  }
  .menu {
    margin-top: 0px;
  }

  .scale {
    transform: scale(0.6);
    transform-origin: left top;
  }
}
@media (max-device-height: 400px) {
  #ref4-menu-flex {
    flex-direction: row;

    transform: scale(0.6);
    transform-origin: top;
  }
  .menu {
    margin-top: 0px;
  }

  .scale {
    transform: scale(0.6);
    transform-origin: left top;
  }
}
@media (max-device-width: 400px) {
  #ref4-menu-flex {
    transform: scale(0.6);
    transform-origin: top;
  }

  .scale {
    transform: scale(0.6);
    transform-origin: left top;
  }
}
</style>
