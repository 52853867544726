<template>
  <div id="ref4-overview-flex">
    <div id="overviewwrapper" class="menu-size absolute hiddentolower">
      <div id="overview-upper" class="flex-row">
        <div>
          <h3>{{overlaySetup.headline}}</h3>
        </div>
        <div id="separatorv"></div>
        <div class="overview-upper-sl flex-column align-items-right">
          <h4>{{overlaySetup.subline1_1 + price + overlaySetup.subline1_3}}</h4>
          <h6>{{overlaySetup.subline2}}</h6>
        </div>
      </div>
      <div id="overview-lower" class="flex-column">
        <div class="flex-row justify-space-between">
          <div>{{overlaySetup.details[0]}}</div>
          <div>{{overlaySetup.details[2]}}</div>
        </div>
        <div class="flex-row justify-space-between">
          <div id="separatorh1"></div>
          <div id="separatorh2"></div>
        </div>
        <div class="flex-row justify-space-between">
          <div>{{overlaySetup.details[1]}}</div>
          <div>{{overlaySetup.details[3]}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ref4SetupOverview',
  data () {
    return {
      overlaySetup: {
        details: []
      }
    }
  },
  props: {
    show: { required: true, type: Boolean },
    price: { required: true, type: Number }
  },
  watch: {
    show () {
      // console.log("watch ", this.id, this.show)
      if (this.show) {
        document
          .getElementById('overviewwrapper')
          .classList.remove('hiddentolower')
      } else {
        document
          .getElementById('overviewwrapper')
          .classList.add('hiddentolower')
      }
    }
  },
  mounted () {
    this.changeData(require('@/data/content_reference4.json'))
  },
  methods: {
    changeData: function (data) {
      this.overlaySetup = data.menu.menuSetup.summaryOverlay
    }
  }
}
</script>

<style lang="scss" scoped>
.justify-space-between {
  justify-content: space-between;
}
h3 {
  color: white !important;
  text-align: left;
}
h4 {
  margin-top: 2px;
  text-align: right;
  transform: scale(1.2);
  transform-origin: right;
  margin-left: 10px;
}
h6 {
  text-align: right;
  margin-top: -10px;
}
// .overview-upper-sl {
//   // color: #8d8d8d;
// }
#separatorv {
  width: 1.5px;
  height: 38px;
  background-color: #3e3e3e;
  margin: auto;
  margin-top: 5px;
}
#separatorh1 {
  width: 185px;
  height: 1.5px;
  background-color: #3e3e3e;
}
#separatorh2 {
  width: 155px;
  height: 1.5px;
  background-color: #3e3e3e;
}
@font-face {
  font-family: "HelveticaLTStd-UltraComp";
  src: url("/fonts/Helvetica_neue/HelveticaLTStd-UltraComp.ttf");
}
@font-face {
  font-family: "HelveticaNeueLTStd-Roman";
  src: url("/fonts/Helvetica_neue/HelveticaNeueLTStd-Roman.ttf");
}
#overview-lower {
  font-family: "HelveticaNeueLTStd-Roman";
  font-size: 14px;
}
#overview-upper {
  font-family: "HelveticaLTStd-UltraComp";
}
.menu-size {
  height: 120px;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -o-transition: height 0.5s;
  width: 400px;
}
.absolute {
  position: absolute;
  margin: auto;
  bottom: 40px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.align-items-right {
  align-items: flex-end;
}
#ref4-overview-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#overviewwrapper {
  background-color: black;
  border: 1px solid #ff6600;
  border-radius: 8px;
  -moz-border-radius: 8px;
  //   display: unset;

  //   transition-delay: display 0s;
  //   transform: translateY(0px);
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;

  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
.hiddentolower {
  //   display: none !important;
  // opacity: 0;
  // visibility: hidden;
  //   transition-delay: display 1s;
   transform: translateY(200px) !important;
  -moz-transform: translateY(200px) !important;
  -webkit-transform: translateY(200px) !important;
  -o-transform: translateY(200px) !important;
  -ms-transform: translateY(200px) !important;

  transition: all 1s !important;
  -webkit-transition: all 1s !important;
  -moz-transition: all 1s !important;
  -o-transition: all 1s !important;
}
@media (max-device-height: 600px) {
  .absolute {
    bottom: 20px;
  }
}
@media (max-device-height: 400px) {
  .absolute {
    bottom: 20px;
    transform: scale(0.6);
    transform-origin: bottom;
  }
}
@media (max-device-width: 500px) {
  .absolute {
    bottom: 20px;
    transform: scale(0.8);
    transform-origin: bottom;
  }
}
@media (max-device-width: 400px) {
  .absolute {
    bottom: 20px;
    transform: scale(0.6);
    transform-origin: bottom;
  }
}
</style>
