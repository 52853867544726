// let selectedPartId = -1;

import store from '../store'

function registerClickEvents () {
  // main menu buttons
  window.addEventListener(
    'Ref4_Config_Click',
    (e) => {
      const id = e.detail.id

      if (id.length === 3 && id.startsWith('0.')) {
        showColorMenu(true)
        var subid = id.replace('0.', '')
        selectPartHighlightById(subid)
        store.dispatch('setSelectedPartId', subid)
        // console.log("0.");
      } else {
        showColorMenu(false)
        selectPartHighlight('')
        if (id === '-1') {
          store.dispatch('setSelectedPartId', id)
        }
      }

      //   console.log("main menu click: ", id)
    },
    false
  )

  window.addEventListener(
    'Ref4_Config_ToggleState',
    (e) => {
      const id = e.detail.id
      const state = e.detail.state

      if (id === '2') {
        showOverview(state)
      } else if (id.length === 3 && id.startsWith('1.')) {
        var subid = id.replace('1.', '')
        showHideAddOn(subid, state)
        // console.log("1.");
      } else {
        showOverview(false)
      }

      //   console.log("main menu toggle state: ", id, state)
    },
    false
  )

  window.addEventListener(
    'Ref4_Color_Click',
    (e) => {
      const id = e.detail.id

      //   if (id.length === 3 && id.startsWith("0.")) {
      //     showColorMenu(true)
      //     var subid = id.replace("0.", "")
      //     selectPartHighlightById(subid)
      //     // console.log("0.");
      //   } else {
      //     showColorMenu(false)
      //     selectPartHighlight("")
      //   }

      // currentColors[selectedPartId] = colorSetup[Number(id.replace("color", ""))]
      // store.state.currentConfiguration.colors[selectedPartId] = colorSetup[Number(id.replace("color", ""))]
      store.dispatch('setPartColor', {
        partid: Number(store.state.currentConfiguration.selectedPartId),
        color: colorSetup[Number(id.replace('color', ''))]
      })

      // console.log("color menu click: ", id)

      sendColors()
    },
    false
  )
}

function register3dModelEvents () {
  window.addEventListener(
    'Ref4_ModelLoaded',
    (e) => {
      sendColors()
    },
    false
  )
}

function showOverview (show) {
  const event = new CustomEvent('Ref4_ChangeOverviewState', {
    detail: {
      show: show
    }
  })
  window.dispatchEvent(event)
}

function showColorMenu (show) {
  const event = new CustomEvent('Ref4_ChangeColorMenuState', {
    detail: {
      show: show
    }
  })
  window.dispatchEvent(event)
}

function showHideAddOn (id, state) {
  const event = new CustomEvent('Ref4_ChangeAddOnState', {
    detail: {
      addonid: id,
      showState: state
    }
  })
  window.dispatchEvent(event)

  store.dispatch('setAddonState', {
    id: Number(id),
    state: state
  })
  // console.log(store.state.currentConfiguration.addons)
}

function selectPartHighlightById (id) {
  // id -> name
  //   console.log(partSetup[id].target)
  selectPartHighlight(partSetup[id].target)
}

function selectPartHighlight (name) {
  const event = new CustomEvent('Ref4_PartSelected', {
    detail: name
  })
  window.dispatchEvent(event)
}

let partSetup = []
let colorSetup = []
let accessorySetup = []

function loadData (data) {
  partSetup = data.menu.partSetup
  colorSetup = data.menu.colorSetup
  accessorySetup = data.menu.accessorySetup
  // currentColors = []

  store.dispatch('resetAddons')
  store.dispatch('resetColors')

  partSetup.forEach(part => {
    var tempDefaultColor = colorSetup.find(color => color.name === part.defaultColor)

    // currentColors.push(tempDefaultColor)
    // store.state.currentConfiguration.colors.push(tempDefaultColor)

    store.dispatch('addColor', tempDefaultColor)
  })
  accessorySetup.forEach(addon => {
    addon.state = false
    // store.state.currentConfiguration.addons.push(addon)
    store.dispatch('addAddon', addon)
  })

  //   console.log(partSetup)
}

// let currentColors = []

function sendColors () {
  const event = new CustomEvent('Ref4_ColorsChanged', {
    // detail: currentColors
    detail: store.state.currentConfiguration.colors
  })
  window.dispatchEvent(event)
}

(function main () {
  // load data
  loadData(require('@/data/content_reference4.json'))
  // register menu events
  registerClickEvents()
  register3dModelEvents()
  // show hide overview / color menu
  // set 3d scene updates
  // calculate price

  // console.log(store.state.currentConfiguration)
}())
