<template>
  <div id="buttonwrapper" v-show="show">
    <div
      :id="animationContainerName"
      class="animationcontainer"
      @click="buttonClick"
    >
      <div id="border" class="circle0"></div>
      <div :id="clickName" class="circle" :style="backgroundColor"></div>
      <div :id="circleName" class="circle2 opacity0"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorButton',
  data () {
    return {
      toggleState: false,
      animationContainerName: 'animationcontainer' + this.id,
      circleName: 'circle' + this.id,
      clickName: 'colorClick' + this.id,
      show: false
    }
  },
  props: {
    color: { required: true, type: String },
    id: { required: true, type: String },
    mainApplication: { required: true, type: String },
    // show: { required: true, type: Boolean },
    useAsToggle: { required: true, type: Boolean },
    useRadioLogic: { type: Boolean, default: false }
  },
  computed: {
    backgroundColor () {
      return 'background:' + this.color
    }
  },
  mounted () {
    if (this.useAsToggle) {
      window.addEventListener(
        'Ref4_Config_Click',
        (e) => {
          if (
            this.$store.state.currentConfiguration.colors[
              this.$store.state.currentConfiguration.selectedPartId
            ] &&
            this.$store.state.currentConfiguration.colors[
              this.$store.state.currentConfiguration.selectedPartId
            ].color === this.color
          ) {
            if (this.useRadioLogic) {
              this.toggleState = true

              // console.log("clicked", this.id, this.toggleState)
            } else {
              this.toggleState = !this.toggleState
            }
          } else {
            if (this.useRadioLogic) {
              this.toggleState = false
            }
          }
          this.setBackgroundColors()
        },
        false
      )
      // part selection changed
      window.addEventListener(
        this.mainApplication + '_Click',
        (e) => {
          if (e.detail.id === this.id) {
            if (this.useRadioLogic) {
              this.toggleState = true

              // console.log("clicked", this.id, this.toggleState)
            } else {
              this.toggleState = !this.toggleState
            }
          } else {
            if (this.useRadioLogic) {
              this.toggleState = false
            }
          }
          this.setBackgroundColors()
        },
        false
      )
    }

    window.addEventListener(
      this.mainApplication + '_SetAvailableColors',
      (e) => {
        this.show = e.detail[Number(this.id.replace('color', ''))]
      },
      false
    )
  },
  watch: {
    id () {
      animationContainerName = 'animationcontainer' + id
      circleName = 'circle' + id
      // console.log("id", animationContainerName, circleName)
    }
    // toggleState(){

    //   this.setBackgroundColors();
    // }
  },
  methods: {
    buttonClick () {
      // Send Click
      const event = new CustomEvent(this.mainApplication + '_Click', {
        detail: {
          id: this.id
        }
      })
      window.dispatchEvent(event)
      // console.log("click", this.id)
    },
    setBackgroundColors () {
      if (this.toggleState) {
        document.getElementById(this.circleName).classList.remove('opacity0')
        document.getElementById(this.circleName).classList.add('opacity1')
      } else {
        document.getElementById(this.circleName).classList.remove('opacity1')
        document.getElementById(this.circleName).classList.add('opacity0')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#buttonwrapper {
  // background-color: red;
  height: 40px;
  width: 40px;
  margin-bottom: 15px;
  margin-right: 10px;
}
.circle0 {
  float: left;
  width: 40px;
  height: 40px;
  shape-outside: circle();
  clip-path: circle();
  position: absolute;
  background: #3e3e3e;
}
.circle {
  float: left;
  width: 38px;
  height: 38px;
  margin: 1px;
  shape-outside: circle();
  clip-path: circle();
  position: absolute;
}
.circle2 {
  float: left;
  width: 16px;
  height: 16px;
  margin: 12px;
  shape-outside: circle();
  clip-path: circle();
  position: absolute;
  background: white;
  z-index: 10;
}
.opacity0 {
  opacity: 0;
}
.opacity1 {
  opacity: 1;
}
#button {
  position: absolute;
  pointer-events: all;
}
.hidden {
  display: none;
}
.animationcontainer {
  pointer-events: all;
}
.animationcontainer:hover {
  opacity: 0.5;
}
.animationcontainer:active {
  transform: scale(0.95);
}
</style>
