<template>
  <div id="textwrapper" :style="letterSpacing">
    <div :id="animationContainerName"
      class="animationcontainer">
      <div id="separator"></div>
      [{{ content }}]
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextHideAnimation',
  data () {
    return {
      toggleState: false,
      animationContainerName: 'text-animationcontainer' + this.id
    }
  },
  props: {
    id: { required: true, type: String },
    content: { required: true, type: String },
    direction: { required: true, type: String },
    show: { required: true, type: Boolean },
    letterspacing: { type: String }
  },
  computed: {
    letterSpacing () {
      return this.letterspacing ? 'letter-spacing:' + this.letterspacing + 'px' : ''
    }
  },
  watch: {
    show () {
      // console.log("watch ", this.id, this.show)
      if (this.show) {
        if (this.direction === 'left') {
          document
            .getElementById(this.animationContainerName)
            .classList.add('left-center')
          document
            .getElementById(this.animationContainerName)
            .classList.remove('left')
        } else {
          document
            .getElementById(this.animationContainerName)
            .classList.add('right-center')
          document
            .getElementById(this.animationContainerName)
            .classList.remove('right')
        }
      } else {
        if (this.direction === 'left') {
          document
            .getElementById(this.animationContainerName)
            .classList.add('left')
          document
            .getElementById(this.animationContainerName)
            .classList.remove('left-center')
        } else {
          document
            .getElementById(this.animationContainerName)
            .classList.add('right')
          document
            .getElementById(this.animationContainerName)
            .classList.remove('right-center')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#separator{
  width: 50px;
  height: 1.5px;
  background-color: #3e3e3e;
  margin: auto;
}
@font-face {
    font-family: 'HelveticaLTStd-UltraComp';
    src: url("/fonts/Helvetica_neue/HelveticaLTStd-UltraComp.ttf");
}

#textwrapper {
  // background-color: red;
  font-family: 'HelveticaLTStd-UltraComp';
  // letter-spacing: 3px;
  color: #3e3e3e;
  height: 50px;
  width: 60px;
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-bottom: 15px;
  margin-top: 10px;
  text-align: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.animationcontainer {
  width: 60px;
  height: 50px;
  position: absolute;
  // transition: all 1s;
}
.animationcontainer.left {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  left: -60px;
}
.animationcontainer.left-center {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  left: 0px;
}
.animationcontainer.right {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  right: -60px;
}
.animationcontainer.right-center {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  right: 0px;
}
</style>
