/* jshint esversion: 6 */

import * as THREE from 'three/build/three.module'
import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader'
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'

// import Stats from 'three/examples/jsm/libs/stats.module'

import {
  RoughnessMipmapper
} from 'three/examples/jsm/utils/RoughnessMipmapper'

import {
  RGBELoader
} from 'three/examples/jsm/loaders/RGBELoader'

import {
  EffectComposer
} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {
  RenderPass
} from 'three/examples/jsm/postprocessing/RenderPass.js'
// import {
//   UnrealBloomPass
// } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import {
  OutlinePass
} from 'three/examples/jsm/postprocessing/OutlinePass.js'
// import {
//   OutlinePass
// } from './custom_OutlinePass.js'
import {
  ShaderPass
} from 'three/examples/jsm/postprocessing/ShaderPass.js'
import {
  FXAAShader
} from 'three/examples/jsm/shaders/FXAAShader.js'
// import {
//   Color
// } from 'three'

/// /////////////////////////
//
// keep your hands off everything above
//
// Setup model below
//
/// /////////////////////////

/* model name & path */
const modelUrl = '3dmodels/reference_4_model/Bike_Web_11_Bike_Hotspots.glb'
const bgModelUrl = '3dmodels/reference_4_model/concept_bike_background.glb'
const floorModelUrl = '3dmodels/reference_4_model/concept_bike_ground.glb'

const addOnModels = [
  '3dmodels/reference_4_model/Frontschutz.glb',
  '3dmodels/reference_4_model/Heckschutz.glb',
  '3dmodels/reference_4_model/Ruecklicht_ohne_licht.glb',
  '3dmodels/reference_4_model/Frontlicht_ohne_licht.glb'
]

/* light & shadow setup */
const useLights = true
const useShadows = false

/* background setup: color names or hex codes (0xff0000) -> red */
const backgroundColor = '#111111'

/* hdr / environment setup */
const environmentName = 'img/hdr/reference4_environment.hdr'
const useEnvironment = true
const showEnvironment = false
const exposure = 1

/* debug helper */
// let logModelChildNames = true;
// const useStats = true

/* post processe */
const usePostProcesses = true

/// /////////////////////////
//
// Setup model above
//
// keep your hands off everything that follows below
//
/// /////////////////////////

let scene, camera, renderer, mixer, clock, pmremGenerator, controls, stats, composer, outlinePass, effectFXAA

let canvas, container, devicePixelRatio, isMobile
let routeActivated = false

const hotspotObjects = []
const hotspotLastStateObjects = []

let modelLoaded = false
const menuOffset = 76

const colorParts = []

// let orbitChanged = true;

function setUpRender () {
  canvas = document.querySelector('#canvas-reference-4')
  container = document.querySelector('#container-reference-4')
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight - menuOffset
  // canvas.width = window.screen.width * window.devicePixelRatio
  // canvas.height = window.screen.height * window.devicePixelRatio - menuOffset

  renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true,
    powerPreference: 'high-performance'
  })

  renderer.autoClear = false

  if (useShadows) {
    renderer.shadowMap.enabled = true
    // renderer.shadowMapSoft = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
  }
  // if (isMobile) {
  //   devicePixelRatio = window.devicePixelRatio
  //   renderer.setPixelRatio(devicePixelRatio)
  // // }
  renderer.setSize(canvas.width, canvas.height)

  pmremGenerator = new THREE.PMREMGenerator(renderer)
  pmremGenerator.compileEquirectangularShader()

  renderer.toneMapping = THREE.NoToneMapping
  renderer.toneMappingExposure = exposure
  renderer.physicallyCorrectLights = true

  renderer.outputEncoding = THREE.sRGBEncoding
  // console.log(renderer.outputEncoding)

  // if (useStats) {
  //   stats = new Stats()
  //   container.appendChild(stats.dom)
  // }
};

async function setupUpPostProcessing () {
  composer = new EffectComposer(renderer)

  composer.addPass(new RenderPass(scene, camera))

  // const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
  // bloomPass.threshold = 0.25;
  // bloomPass.strength = 2;
  // bloomPass.radius = 0.5;

  // composer.addPass(bloomPass);

  outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), scene, camera)

  outlinePass.edgeStrength = 2

  outlinePass.edgeGlow = 1.5

  outlinePass.edgeThickness = 1
  outlinePass.pulsePeriod = 3

  outlinePass.visibleEdgeColor.set('#ff6600') // opposite of #00977e
  outlinePass.hiddenEdgeColor.set('#7f2b00')

  composer.addPass(outlinePass)

  effectFXAA = new ShaderPass(FXAAShader)
  effectFXAA.uniforms.resolution.value.set(1 / canvas.width, 1 / canvas.height)
  composer.addPass(effectFXAA)
}

function setUpCamera () {
  const fov = 60
  const aspect = canvas.width / canvas.height
  const near = 0.1
  const far = 150000

  camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
  camera.position.z = 2
}

function setUpThreeScene () {
  scene = new THREE.Scene()
  scene.background = new THREE.Color(backgroundColor)
  // const color = 0xff0000;
  // const density = 0.1;
  // scene.fog = new THREE.FogExp2(color, density);
  scene.add(camera)
  // scene.add(textureCamera);
};

function setUpLight () {
  // const hlight = new THREE.AmbientLight(0x4040aa, 10)
  // scene.add(hlight)

  const directionalLight = new THREE.DirectionalLight(0xccccbb, 20)
  directionalLight.position.set(0, 2, 0)
  // directionalLight.castShadow = true

  // directionalLight.shadow.bias = 0.0005
  // directionalLight.shadow.mapSize.height = 4096
  // directionalLight.shadow.mapSize.width = 4096
  // directionalLight.shadow.camera.visible = true
  // const helper = new THREE.DirectionalLightHelper( directionalLight, 1 ,"red");
  // scene.add( helper );
  scene.add(directionalLight)

  // const pointLight = new THREE.PointLight(0xff0000, 50, 10);
  // pointLight.position.set(0, 2, 0);
  // pointLight.castShadow = true
  // pointLight.shadow.bias = 0.0005
  // pointLight.shadow.mapSize.height = 4096
  // pointLight.shadow.mapSize.width = 4096
  // scene.add(pointLight);

  // const sphereSize = 1;
  // const pointLightHelper = new THREE.PointLightHelper(pointLight, sphereSize);
  // scene.add(pointLightHelper);

  // const spotLight0 = new THREE.SpotLight(0xff0000, 50000, 24, 0.1, 1, 0, 0.4);
  // spotLight0.position.set(0, 20, 0);

  // spotLight0.castShadow = true;
  // spotLight0.shadow.bias = 0.0005
  // spotLight0.shadow.mapSize.height = 4096
  // spotLight0.shadow.mapSize.width = 4096
  // const spotLightHelper0 = new THREE.SpotLightHelper(spotLight0);
  // scene.add(spotLightHelper0);
  // scene.add(spotLight0);

  const spotLight = new THREE.SpotLight(0xccccbb, 100, 2.7, 0.6, 1, 1, 1)
  spotLight.position.set(0.5, 1, 2)

  spotLight.castShadow = false
  // const spotLightHelper = new THREE.SpotLightHelper(spotLight, "blue");
  // scene.add(spotLightHelper);
  scene.add(spotLight)

  const spotLight2 = new THREE.SpotLight(0xffffbb, 150, 3, 0.6, 1, 1, 1)
  spotLight2.position.set(-0.5, 1, -2)

  spotLight2.castShadow = false
  // const spotLightHelper2 = new THREE.SpotLightHelper(spotLight2, "green");
  // scene.add(spotLightHelper2);
  scene.add(spotLight2)
};

function setUpControls () {
  // const controls = new OrbitControls(camera, renderer.domElement);
  controls = new OrbitControls(camera, canvas)

  controls.enableZoom = true // Zooming
  controls.minDistance = 1
  controls.maxDistance = 2
  controls.enablePan = false

  // controls.target.set(10000000, 100, 10000000);
  // controls.update();

  controls.autoRotate = true // enable rotation
  controls.maxPolarAngle = Math.PI / 2 // Limit angle of visibility
  // controls.maxPolarAngle = Math.PI * 2
  // controls.minPolarAngle = 0
  // controls.addEventListener('change', onOrbitChanged);

  var autorotateTimeout
  // stop autorotate after the first interaction
  controls.addEventListener('start', function () {
    clearTimeout(autorotateTimeout)
    controls.autoRotate = false
  })

  // restart autorotate after the last interaction & an idle time has passed
  controls.addEventListener('end', function () {
    autorotateTimeout = setTimeout(function () {
      controls.autoRotate = true
    }, 10000)
  })

  return controls
}

function setUpEnvironment () {
  new RGBELoader()
    .setDataType(THREE.UnsignedByteType)
    .load(environmentName, function (texture) {
      var envMap = pmremGenerator.fromEquirectangular(texture).texture

      if (showEnvironment) {
        scene.background = envMap
      }
      if (useEnvironment) {
        scene.environment = envMap
      }

      texture.dispose()
      pmremGenerator.dispose()
    })
}

const modelCount = 3
let modelsLoaded = 0

let bgProgress = 0
let floorProgress = 0
let modelProgress = 0
let allProgress = 0

function addModelToScene (_modelurl, _unlit, _position, _rotation, _color, _opacity, _objectname) {
  modelLoaded = false
  let model
  const loader = new GLTFLoader()

  // A reusable function to set up the models. We're passing in a position parameter
  // so that they can be individually placed around the scene
  const onLoad = (gltf, position) => {
    // use of RoughnessMipmapper is optional
    var roughnessMipmapper = new RoughnessMipmapper(renderer)

    model = gltf.scene // .children[0];

    model.scale.set(1, 1, 1)
    model.position.copy(position)
    model.rotateX(_rotation.x)
    model.rotateY(_rotation.y)
    model.rotateZ(_rotation.z)

    model.traverse(function (child) {
      // shadows
      if (child.isMesh) {
        roughnessMipmapper.generateMipmaps(child.material)

        if (useShadows) {
          if (child.name.includes('##receive')) {
            child.receiveShadow = true
            // console.log("RECEIVE - " + child.name);
          }
          if (child.name.includes('##cast')) {
            child.castShadow = true
            // console.log("CAST - " + child.name);
          }
        }

        const mat = child.material
        // materials.push(mat);
        if (mat.name.includes('##coloradjust')) {
          const part = child
          part.name = mat.name

          colorParts.push(part)
        }
        if (_unlit) {
          // console.log("original", child.material)
          child.material = new THREE.MeshBasicMaterial({
            color: _color || mat.color,
            map: mat.map
            // skinning: true
          })
          if (_opacity) {
            child.material.transparent = true
            child.material.opacity = _opacity
          }
          child.material.toneMapped = false
          if (child.material.map) {
            child.material.map.encoding = THREE.LinearEncoding
            // console.log(child.material.map.encoding)
          }
          // console.log("unlit", child.material)
        }
      } else if (child.isLight) {
        child.shadow.camera.visible = true
        if (useShadows && child.name.includes('##cast')) {
          child.castShadow = true
          child.shadow.bias = 0.0005
          child.shadow.mapSize.height = 4096
          child.shadow.mapSize.width = 4096
          child.shadow.camera.visible = true
        }
      }
      // hotspots
      if (child.name.includes('##hotspot-')) {
        const splits = child.name.split('##')
        const hotspot = {}
        const lastStateHotspot = {}

        splits.forEach(split => {
          // console.log("SPLIT - " + split);
          if (split.includes('hotspot-')) {
            const secSplits = split.split('-')
            if (secSplits.length > 1) {
              hotspot.id = secSplits[1]
              lastStateHotspot.id = hotspot.id
            }
          }
        })

        hotspot.name = splits[0]
        hotspot.object = child
        lastStateHotspot.x = hotspot.object.position.x
        lastStateHotspot.y = hotspot.object.position.y
        lastStateHotspot.z = hotspot.object.position.z

        hotspotObjects.push(hotspot)
        hotspotLastStateObjects.push(lastStateHotspot)
        // console.log(hotspot);
      }
      // if (logModelChildNames) {
      // console.log(child.name);
      // }
    })
    // console.log(colorParts)
    if (_objectname) {
      model.name = _objectname
      // console.log(_objectname)

      if (model.name.includes('addOn')) {
        addonParts.push(model)
        // console.log(addonParts)

        highlightAddon(model.name)
      }
    }
    scene.add(model)
    roughnessMipmapper.dispose()

    mixer = new THREE.AnimationMixer(model)
    // gltf.animations.forEach((clip) => {
    //     mixer.clipAction(clip).play();
    // });

    modelLoaded = true
    // console.log('finished model loading');

    modelsLoaded++

    if (modelsLoaded === modelCount) {
      const event = new Event('Ref4_ModelLoaded')

      window.dispatchEvent(event)
    }
    hotspotObjects.forEach((hotspot) => {
      HotspotPositionChanged(hotspot)
    })
  }

  // the loader will report the loading progress to this function
  const onProgress = (progress) => {
    if (_objectname === 'bg' || _objectname === 'floor' || _objectname === 'model') {
      // console.log((progress.loaded / progress.total), _objectname)
      switch (_objectname) {
        case 'bg':
          bgProgress = progress.loaded / progress.total
          break
        case 'floor':
          floorProgress = progress.loaded / progress.total
          break
        case 'model':
          modelProgress = progress.loaded / progress.total
          break
        default:
          break
      }
      allProgress = (bgProgress + floorProgress + modelProgress) / modelCount
      // console.log('all', allProgress)
      const event = new CustomEvent('ref4_loadingprogress', {
        detail: allProgress
      })

      window.dispatchEvent(event)
    }
  }

  // the loader will send any error messages to this function, and we'll log
  // them to to console
  const onError = (errorMessage) => {
    console.log(errorMessage)
  }

  // load the first model. Each model is loaded asynchronously,
  // so don't make any assumption about which one will finish loading first
  const modelPosition = _position

  loader.load(_modelurl, gltf => onLoad(gltf, modelPosition), onProgress, onError)
  // console.log("add:")
  // console.log(scene.children)
  return model
};

function removeModelFromScene (_objectname) {
  var selectedObject = scene.getObjectByName(_objectname)

  addonParts = arrayRemove(addonParts, selectedObject)
  // console.log("remove", addonParts)
  if (selectedObject) {
    scene.remove(selectedObject)
    disposeObject(selectedObject)
  }
}

function disposeObject (obj) {
  if (obj !== null) {
    for (var i = 0; i < obj.children.length; i++) {
      disposeObject(obj.children[i])
    }
    if (obj.geometry) {
      obj.geometry.dispose()
      obj.geometry = undefined
    }
    if (obj.material) {
      if (obj.material.map) {
        obj.material.map.dispose()
        obj.material.map = undefined
      }
      obj.material.dispose()
      obj.material = undefined
    }
  }
  obj = undefined
  return obj
};

let addonParts = []
async function addModelOnClick (_modelid) {
  if (!scene.getObjectByName('addOn' + _modelid)) {
    await addModelToScene(addOnModels[_modelid], false, new THREE.Vector3(0, -0.3, 0), new THREE.Vector3(0, Math.PI, 0), null, null, 'addOn' + _modelid)
  }
}

function removeModelOnClick (_modelid) {
  removeModelFromScene('addOn' + _modelid)
}

function arrayRemove (arr, value) {
  return arr.filter(function (ele) {
    return ele != value
  })
}

function onAddonStateChanged (e) {
  // console.log(e.detail)
  if (e.detail.showState) {
    addModelOnClick(e.detail.addonid)
  } else {
    removeModelOnClick(e.detail.addonid)
  }
}

function highlightAddon (addonName) {
  // console.log("hl")
  outlinePass.selectedObjects = []

  addonParts.forEach(part => {
    // console.log(addonName, part.name)
    if (addonName === part.name) {
      // console.log(addonName, part.name)
      outlinePass.selectedObjects.push(part)
    }
  })
}

function onWindowResize () {
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight - menuOffset

  // console.log("REF4 Window resized")
  // canvas.width = window.screen.width * window.devicePixelRatio
  // canvas.height = window.screen.height * window.devicePixelRatio - menuOffset

  camera.aspect = canvas.width / canvas.height

  camera.updateProjectionMatrix()

  // if (isMobile) {
  //   devicePixelRatio = window.devicePixelRatio
  //   renderer.setPixelRatio(window.devicePixelRatio)
  // }
  renderer.setSize(canvas.width, canvas.height)
}

function onRouteActivated () {
  if (controls) {
    controls.reset()
  }
  routeActivated = true
  // console.log('ref 4 activated');
  requestAnimationFrame(render)
  onWindowResize()
}

function onRouteDeactivated () {
  routeActivated = false
  // console.log('ref 4 deactivated');
}

function onPartSelected (e) {
  // console.log(e.detail);
  outlinePass.selectedObjects = []

  colorParts.forEach(part => {
    if (e.detail === part.name) {
      outlinePass.selectedObjects.push(part)
    }
  })
}

function onColorChanged (e) {
  // console.log(e.detail)

  var currentColors = e.detail

  // console.log("color changed: ", colorParts)

  for (let i = 0; i < colorParts.length; i++) {
    const part = colorParts[i]
    const currentColor = currentColors[i]
    part.material.color.set(Number(currentColor.color.replace('#', '0x')))
    part.material.emissive.set(Number(currentColor.emissive.replace('#', '0x')))
    if (part.material.specular) {
      part.material.specular.set(Number(currentColor.specular.replace('#', '0x')))
    }
    part.material.roughness = currentColor.roughness
    part.material.metalness = currentColor.metalness

    // mat.color.set(0xff0000);
    // mat.opacity = 0.3;
    // mat.transparent = true;
    // mat.emissive.set(0xff0000);
    // mat.emissiveIntensity = 5;
  }
}

function HotspotPositionChanged (hotspot) {
  // TODO calc screenspace position
  const screenPosition = toScreenPosition(hotspot.object, camera)

  const event = new CustomEvent('Ref4_HotspotPositionChanged', {
    detail: {
      id: hotspot.id,
      x: screenPosition.x,
      y: screenPosition.y + menuOffset / 2
    }
  })

  window.dispatchEvent(event)
}

function toScreenPosition (obj, camera) {
  var vector = new THREE.Vector3()

  var widthHalf = 0.5 * canvas.width
  var heightHalf = 0.5 * canvas.height

  obj.updateMatrixWorld()
  camera.updateProjectionMatrix()
  vector.setFromMatrixPosition(obj.matrixWorld)
  vector.project(camera)

  vector.x = (vector.x * widthHalf) + widthHalf
  vector.y = -(vector.y * heightHalf) + heightHalf

  return {
    x: vector.x,
    y: vector.y
  }
};

function render (time) {
  time *= 0.001 // convert time to seconds

  // last = now;
  // lastdiff = diff;
  // now = performance.now()
  // diff = now - last;
  // // console.log(diff);

  var delta = clock.getDelta()

  if (mixer) {
    mixer.update(delta)
  }

  if (usePostProcesses) {
    composer.render()
  } else {
    renderer.render(scene, camera)
  }

  // if (useStats) {
  //   stats.update()
  // }

  if (modelLoaded) {
    hotspotObjects.forEach((hotspot) => {
      hotspotLastStateObjects.forEach((lastStateHotspot) => {
        if (hotspot.id === lastStateHotspot.id) {
          HotspotPositionChanged(hotspot)

          lastStateHotspot.x = hotspot.object.position.x
          lastStateHotspot.y = hotspot.object.position.y
          lastStateHotspot.z = hotspot.object.position.z
        }
      })
    })
  }

  // orbitChanged = false;
  controls.update()
  if (routeActivated) {
    requestAnimationFrame(render)
  }
};

export async function main (_isMobile) {
  isMobile = _isMobile
  clock = new THREE.Clock()
  // console.log("in scene ", _isMobile)
  // useShadows = _isMobile ? false : true;
  setUpRender()

  setUpCamera()

  setUpThreeScene()

  if (useLights) {
    setUpLight()
  }
  setUpControls(camera, renderer)

  if (useEnvironment || showEnvironment) {
    setUpEnvironment()
  }

  if (usePostProcesses) {
    setupUpPostProcessing()
  }

  window.addEventListener('resize', onWindowResize, false)
  window.addEventListener('Ref4_routeActivated', onRouteActivated, false)
  window.addEventListener('Ref4_routeDeactivated', onRouteDeactivated, false)
  window.addEventListener('Ref4_PartSelected', onPartSelected, false)
  window.addEventListener('Ref4_ColorsChanged', onColorChanged, false)
  window.addEventListener('Ref4_ChangeAddOnState', onAddonStateChanged, false)
  await addModelToScene(bgModelUrl, true, new THREE.Vector3(0, -0.3, 0), new THREE.Vector3(0, 0, 0), 0x3e3e3e, 0.1, 'bg')
  await addModelToScene(floorModelUrl, true, new THREE.Vector3(0, -0.275, 0), new THREE.Vector3(0, 0.5 * Math.PI, 0), 0xffffff, 1, 'floor')
  await addModelToScene(modelUrl, false, new THREE.Vector3(0, -0.3, 0), new THREE.Vector3(0, Math.PI, 0), 0xffffff, 1, 'model')

  // requestAnimationFrame(render)

  // if (_isMobile) {
  //   setInterval(() => {
  //     setPixelRatioOnPerformance()
  //   }, 5000);
  // }
}
