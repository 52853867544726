<template>
  <div id="menuwrapper" class="menu-size hiddentoleft">
    <div id="groupwrapper0" class="absolute">
      <button-hide-animation
        v-for="(item, index) in buttonData0"
        :key="index"
        :icon="item"
        :useAsToggle="true"
        :show="show0"
        mainApplication="Ref4_Config"
        :id="'' + index"
        direction="left"
        :useRadioLogic="true"
      ></button-hide-animation>
    </div>
    <div id="groupwrapper1" class="absolute hidden">
      <button-hide-animation
        :icon="backIcon"
        :useAsToggle="false"
        :show="show1"
        mainApplication="Ref4_Config"
        id="-1"
        direction="right"
        class="scaleDown"
      ></button-hide-animation>
      <button-hide-animation
        v-for="(item, index) in buttonData1"
        :key="index"
        :icon="item.icon"
        :useAsToggle="true"
        :show="show1"
        mainApplication="Ref4_Config"
        :id="'0.' + index"
        direction="right"
        :useRadioLogic="true"
      ></button-hide-animation>
      <text-hide-animation
        id="1"
        direction="right"
        :show="show1"
        :content="menuSetup.configurationHeadlines[1]"
        letterspacing="1.6"
      ></text-hide-animation>
    </div>
    <div id="groupwrapper2" class="absolute hidden">
      <button-hide-animation
        :icon="backIcon"
        :useAsToggle="false"
        :show="show2"
        mainApplication="Ref4_Config"
        id="-2"
        direction="right"
        class="scaleDown"
      ></button-hide-animation>
      <button-hide-animation
        v-for="(item, index) in buttonData2"
        :key="index"
        :icon="item.icon"
        :useAsToggle="true"
        :show="show2"
        mainApplication="Ref4_Config"
        :id="'1.' + index"
        direction="right"
      ></button-hide-animation>
      <text-hide-animation
        id="2"
        direction="right"
        :show="show2"
        :content="menuSetup.configurationHeadlines[2]"
        letterspacing="-0.2"
      ></text-hide-animation>
    </div>
  </div>
</template>

<script>
import ButtonHideAnimation from '@/components/Button_with_HideAnimation.vue'
import TextHideAnimation from '@/components/Text_with_HideAnimation.vue'
export default {
  name: 'Ref4MainMenuAnimated',
  components: {
    ButtonHideAnimation,
    TextHideAnimation
  },
  data () {
    return {
      show0: false,
      show1: true,
      show2: true,
      buttonData0: [],
      buttonData1: [],
      buttonData2: [],
      menuSetup: {
        configurationHeadlines: ['', '', '']
      },
      backIcon: ''
    }
  },
  mounted () {
    // console.log("mounted");
    window.addEventListener(
      'Ref4_Config_Click',
      (e) => {
        const id = e.detail.id
        // console.log("selected id: ", id);

        if (id.length === 1 && id != '2') {
          this.show0 = false

          document.getElementById('menuwrapper').classList.add('height2')
          setTimeout(() => {
            if (id === '0') {
              this.show1 = true
              this.show2 = false
            } else if (id === '1') {
              this.show1 = false
              this.show2 = true
            }
          }, 500)
        } else if (id === '-1' || id === '-2') {
          this.show1 = false
          this.show2 = false

          setTimeout(() => {
            document.getElementById('menuwrapper').classList.remove('height2')
            this.show0 = true
          }, 500)
        }
      },
      false
    )
    window.addEventListener(
      'Ref4_ModelLoaded',
      (e) => {
        document.getElementById('menuwrapper').classList.remove('hiddentoleft')
      },
      false
    )
    this.changeData(require('@/data/content_reference4.json'))
    setTimeout(() => {
      this.show0 = true
      this.show1 = false
      this.show2 = false

      document.getElementById('groupwrapper1').classList.remove('hidden')
      document.getElementById('groupwrapper2').classList.remove('hidden')

      // console.log("timeout");
    }, 10)
  },
  methods: {
    changeData: function (data) {
      this.mobileHint = data.mobileHint
      this.buttonData0 = data.menu.mainToggleIcons
      this.buttonData1 = data.menu.partSetup
      this.buttonData2 = data.menu.accessorySetup
      this.backIcon = data.menu.backIcon
      this.menuSetup = data.menu.menuSetup
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-size {
  height: 210px;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -o-transition: height 0.5s;
  width: 60px;
}
.absolute {
  position: absolute;
}
#menuwrapper {
  background-color: black;
  border: 1px solid #ff6600;
  border-radius: 8px;
  -moz-border-radius: 8px;
  display: unset;
  transition: transform 1s;
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
  -o-transition: transform 1s;
}
.hiddentoleft {
  display: none;
  transform: translateX(-200px);
  transition: transform 1s;
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
  -o-transition: transform 1s;
}
.hidden {
  display: none;
}
.height2 {
  height: 365px !important;
  transition: height 0.5s !important;
  -webkit-transition: height 0.5s !important;
  -moz-transition: height 0.5s !important;
  -o-transition: height 0.5s !important;
}
.scaleDown {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform-origin: top;
}
</style>
