<template>
  <div id="buttonwrapper">
    <div
      :id="animationContainerName"
      class="background-circle-gradient-white animationcontainer"
      @click="buttonClick()"
    >
      <div :id="circleName" class="background-black circle">
        <img id="buttonimage" :src="icon" alt=""  />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonHideAnimation',
  data () {
    return {
      toggleState: false,
      animationContainerName: 'animationcontainer' + this.id,
      circleName: 'circle' + this.id
    }
  },
  props: {
    icon: { required: true, type: String },
    id: { required: true, type: String },
    direction: { required: true, type: String },
    mainApplication: { required: true, type: String },
    useAsToggle: { required: true, type: Boolean },
    show: { required: true, type: Boolean },
    useRadioLogic: { type: Boolean, default: false }
    // externalToggleState: { required: true, type: Boolean },
  },
  mounted () {
    if (this.useAsToggle) {
      window.addEventListener(
        this.mainApplication + '_Click',
        (e) => {
          if (e.detail.id === this.id) {
            if (this.useRadioLogic) {
              this.toggleState = true
            } else {
              this.toggleState = !this.toggleState
            }

            const event = new CustomEvent(
              this.mainApplication + '_ToggleState',
              {
                detail: {
                  id: this.id,
                  state: this.toggleState
                }
              }
            )
            window.dispatchEvent(event)
          } else {
            if (this.useRadioLogic) {
              this.toggleState = false
            }
          }
          this.setBackgroundColors()
        },
        false
      )
    }
  },
  watch: {
    id () {
      animationContainerName = 'animationcontainer' + id
      circleName = 'circle' + id
      // console.log("id", animationContainerName, circleName)
    },
    show () {
      // console.log("watch ", this.id, this.show)
      if (this.show) {
        if (this.direction === 'left') {
          document
            .getElementById(this.animationContainerName)
            .classList.add('left-center')
          document
            .getElementById(this.animationContainerName)
            .classList.remove('left')
        } else {
          document
            .getElementById(this.animationContainerName)
            .classList.add('right-center')
          document
            .getElementById(this.animationContainerName)
            .classList.remove('right')
        }
      } else {
        if (this.direction === 'left') {
          document
            .getElementById(this.animationContainerName)
            .classList.add('left')
          document
            .getElementById(this.animationContainerName)
            .classList.remove('left-center')
        } else {
          document
            .getElementById(this.animationContainerName)
            .classList.add('right')
          document
            .getElementById(this.animationContainerName)
            .classList.remove('right-center')
        }
      }
    }
  },
  methods: {
    buttonClick () {
      // Send Click
      const event = new CustomEvent(this.mainApplication + '_Click', {
        detail: {
          id: this.id
        }
      })
      window.dispatchEvent(event)
    },
    setBackgroundColors () {
      if (this.toggleState) {
        document
          .getElementById(this.animationContainerName)
          .classList.remove('background-circle-gradient-white')
        document
          .getElementById(this.animationContainerName)
          .classList.add('background-circle-gradient-orange')

        document
          .getElementById(this.circleName)
          .classList.remove('background-black')
        document
          .getElementById(this.circleName)
          .classList.add('background-darkorange')
      } else {
        document
          .getElementById(this.animationContainerName)
          .classList.remove('background-circle-gradient-orange')
        document
          .getElementById(this.animationContainerName)
          .classList.add('background-circle-gradient-white')

        document
          .getElementById(this.circleName)
          .classList.remove('background-darkorange')
        document
          .getElementById(this.circleName)
          .classList.add('background-black')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#buttonwrapper {
  // background-color: red;
  height: 50px;
  width: 60px;
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-bottom: 15px;
  margin-top: 10px;
}
.circle {
  float: left;
  width: 40px;
  height: 40px;
  margin: 10px;
  margin-top: 5px;
  shape-outside: circle();
  clip-path: circle();
  position: absolute;
}
.background-black {
  background: rgb(0, 0, 0);
}
.background-darkorange {
  background: rgb(127, 43, 0);
}
#buttonimage {
  width: 32px;
  left: 4px;
  top: 4px;
  height: auto;
  margin: auto;
  z-index: 50;
  position: absolute;
}
#button {
  position: absolute;
  pointer-events: all;
}
.background-circle-gradient-orange {
  background: radial-gradient(
    circle,
    rgb(255, 102, 0) 52%,
    rgba(255, 102, 0, 0.5) 56%,
    rgba(255, 255, 255, 0) 65%
  );
}
.background-circle-gradient-white {
  background: radial-gradient(
    circle,
    rgb(255, 255, 255) 52%,
    rgba(255, 255, 255, 0.5) 56%,
    rgba(255, 255, 255, 0) 65%
  );
}
.animationcontainer {
  width: 60px;
  height: 50px;
  pointer-events: all;
  position: absolute;
  transition: opacity 0s;
  -webkit-transition: opacity 0s;
  -moz-transition: opacity 0s;
  -o-transition: opacity 0s;
}
.animationcontainer:hover {
  opacity: 0.3;
  transition: 0s;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
}
.animationcontainer:active {
  transform: scale(0.95);
  transition: 0s;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
}
.animationcontainer.left {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  left: -60px;
}
.animationcontainer.left-center {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  left: 0px;
}
.animationcontainer.right {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  right: -60px;
}
.animationcontainer.right-center {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  right: 0px;
}
</style>
